@import "node_modules/@tn-equis/core/foundations/scss/abstracts/mixins/flex";
@import "node_modules/@tn-equis/core/foundations/scss/abstracts/functions/functions";
@import "node_modules/@tn-equis/core/foundations/scss/vendor/reset";
@import "node_modules/@tn-equis/core/foundations/scss/themes/global/variables";
@import "node_modules/@tn-equis/core/foundations/scss/base/global";
@import "node_modules/@tn-equis/core/foundations/scss/base/medias";
@import "node_modules/@tn-equis/core/foundations/scss/base/spaces";
@import "node_modules/@tn-equis/core/foundations/scss/base/typography";
@import "node_modules/@tn-equis/core/foundations/scss/icons/iconsFont";
@import "node_modules/@tn-equis/core/foundations/scss/layout/grid";

html {
  scroll-behavior: smooth;
}